import {FormBaseClass} from "../../resources/elements/FormBaseClass";
import {translations} from "../../resources/classes/translations";
import {ConfigService} from "../../resources/services/ConfigService";
import {ReportService} from "resources/services/ReportService";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";

export class patientFall extends FormBaseClass {
    async afterSave(response) {
        await super.afterSave(response);
        this.mailReport();
    }

    async attached(): Promise<void> {
        this.route = ConfigService.FormNames.Fall;
        await super.attached();
        this.buttonText = translations.translate("new_fall");

        if (ConfigService.Debug) {
            window["fall"] = this;
        }
    }

    mailReport() {
        if (!this.setting.settings["sendMailOnSave"]) {
            if (ConfigService.Debug) {
                console.info("No confirmationmail will be send because sendMailOnSave is false");
            }

            return;
        }

        if (this.response.status === fhirEnums.QuestionnaireResponseStatus.amended || this.response.status === fhirEnums.QuestionnaireResponseStatus.completed) {
            ReportService.Mail(this.response.id, this.setting.report.name, this.patient)
                .then(() => {
                    console.info("Mail sent");
                    RuntimeInfo.ShowInfo('Mail gesendet', true);
                })
                .catch((e) => {
                    console.warn("In mailReport:", e.message || e);
                })
        }
    }
}
