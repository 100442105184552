import {inject, TaskQueue} from "aurelia-framework";
import {pointArray} from "../../resources/classes/pointArray";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {Router} from "aurelia-router";
import {FhirService} from "../../resources/services/FhirService";
import {PatientService} from "resources/services/PatientService";
import {DialogMessages} from "resources/services/DialogMessages";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import * as environment from "../../../config/environment.json";
import {NitTools} from "resources/classes/NursitTools";
import {ConfigService} from "../../resources/services/ConfigService";
import {PromptInput} from "../../resources/elements/prompt-input";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import * as moment from "moment";
import {UserService} from "../../resources/services/UserService";

@inject(TaskQueue, I18N, DialogService, Router, FhirService, PatientService, DialogMessages)
export class woundDraw {
    router: Router;
    mediaId: string;
    dialogMessages: DialogMessages;
    media: any;
    taskQueue: TaskQueue;
    canvas: HTMLCanvasElement;
    image: HTMLImageElement;
    svg: HTMLOrSVGElement;
    drawingMode: DrawingMode;
    mouseButtonDown: boolean = false;
    lastPoint: IPoint;
    drawingObjects: IDrawingObject[] = [];
    currentObject: IDrawingObject;
    calibrationInfo: number = undefined;
    fX: number = 1;
    color: string = "#4375b8";
    static zoom: number = 1;
    i18n: I18N;
    dialogService: DialogService;
    fhirService: FhirService;
    patientService: PatientService;

    constructor(taskQueue: TaskQueue, i18n: I18N, dialogService: DialogService, router: Router, fhirService: FhirService, patientService: PatientService, dialogMessages: DialogMessages) {
        this.taskQueue = taskQueue;
        this.patientService = patientService;
        this.dialogMessages = dialogMessages;
        this.i18n = i18n;
        this.i18n.i18next.loadResources();
        this.dialogService = dialogService;
        this.router = router;
        this.fhirService = fhirService;
    }

    get linePoints(): IPoint[] {
        if (this.currentObject) return this.currentObject.points;
    }

    set linePoints(points: IPoint[]) {
        if (this.currentObject) this.currentObject.points = points;
    }

    static formatNumber(n: number, digits: number = 1): string {
        try {
            let s = `${Math.round(n * 100) / 100}`;
            return parseFloat(s).toFixed(digits);
        } catch (e) {
            return n.toFixed(digits);
        }
    }

    encounterId: string;

    activate(params) {
        this.mediaId = params.id;
        this.encounterId = params.encounter;
    }

    styleSizeToNumber(sw: string) {
        return parseInt(sw.replace("px", ""));
    }

    penSize: number = 4;

    get zoom() {
        return woundDraw.zoom;
    }

    setZoom(fac: number) {
        woundDraw.zoom *= fac;

        let f = (this.image.width || this.image.naturalWidth) / (this.image.height || this.image.naturalHeight);
        let w = (this.image.width || this.image.naturalWidth) * woundDraw.zoom; // this.styleSizeToNumber(this.canvas.style.width) * fac;
        let h = w / f; // this.styleSizeToNumber(this.canvas.style.height) * fac;

        this.canvas.style.width = w + "px";
        this.canvas.style.height = h + "px";

        this.update();
    }

    pointFromTouch(touch): IPoint {
        if (!touch) return undefined;
        let rect = (<HTMLElement>this.canvas).getBoundingClientRect();
        let x = touch.pageX - rect.left; //  touch.target.offsetLeft;
        let y = touch.pageY - rect.top; // touch.target.offsetTop;
        return {x: x, y: y};
    }

    touch1: IPoint;
    touch2: IPoint;

    getCoords(e): Point {
        this.ensureCanvas();

        let rect = (<HTMLElement>this.canvas).getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;

        if (e.touches) {
            let p = this.pointFromTouch(e.touches[0]);
            x = p.x;
            y = p.y;
        }

        let fX = this.canvas.width / this.styleSizeToNumber(this.canvas.style.width);
        let fY = this.canvas.height / this.styleSizeToNumber(this.canvas.style.height);

        return new Point(x * fX, y * fY);
    }

    ensureCanvas() {
        if (!this.canvas) {
            this.canvas = <HTMLCanvasElement>document.getElementById("imageCanvas");
        }
    }

    selectMode() {
        this.drawingMode = DrawingMode.select;
    }

    calibrateMode() {
        this.drawingMode = DrawingMode.calibrate;
    }

    lineMode() {
        this.drawingMode = DrawingMode.line;
    }

    freehandMode() {
        this.drawingMode = DrawingMode.freeHand;
    }

    areaMode() {
        this.drawingMode = DrawingMode.area;
    }

    measureMode(measureMode: string) {
        if (measureMode.toUpperCase() === "AREA")
            this.drawingMode = DrawingMode.measureArea;
        else
            this.drawingMode = DrawingMode.measureDistance;
    }

    clear() {
        this.drawingObjects = [];
        this.update();
    }

    update() {
        this.ensureCanvas();

        let ctx = this.canvas.getContext("2d");
        if (this.image) ctx.drawImage(this.image, 0, 0);

        if (this.drawingMode === DrawingMode.calibrate) {
            woundDraw.updateCanvas(ctx, this.currentObject);
        } else {
            this.drawingObjects.forEach(obj => {
                woundDraw.updateCanvas(ctx, obj);
            });
        }
    }

    static updateCanvas(ctx: CanvasRenderingContext2D, object: DrawingObject) {
        if (!object || !object.points || !ctx) return;

        let points = object.points;
        if (points.length === 0) return;
        // ctx.strokeStyle = "#FF0000";
        if (object.color.length > 7)
            object.color = object.color.substr(0, 7)
        ctx.strokeStyle = object.color;

        let defaultFontSize = 18;
        if (this.zoom < 1) {
            defaultFontSize /= this.zoom;
            defaultFontSize = Math.round(defaultFontSize);
        }

        ctx.lineCap = "round";
        ctx.font = `${defaultFontSize}px arial`;
        ctx.fillStyle = "black";

        if (object.type === DrawingMode.measureDistance) {
            ctx.lineCap = "square";
        }

        ctx.lineWidth = Math.round((object.penSize || 2) / this.zoom);
        if (object.type === DrawingMode.measureDistance) {
            ctx.lineWidth = Math.round(2 / this.zoom);
        } else if (object.type === DrawingMode.area) {
            ctx.lineWidth = 1;
        }

        if (object.type === DrawingMode.text) {
            ctx.strokeStyle = object.color;
            let tm: TextMetrics = ctx.measureText("y!" + object.text);
            ctx.lineWidth = 1;
            ctx.fillStyle = object.color;
            let maxW = ctx.canvas.width - object.points[0].x;

            // ctx.fillText(object.text, object.points[0].x, object.points[0].y, maxW);
            let lineCount = this.wrapText(ctx, object, maxW, defaultFontSize);

            let pt2: IPoint = new Point(object.points[0].x + tm.width, object.points[0].y + (defaultFontSize * lineCount));
            if (!object.points[1])
                object.points.push(pt2);
            else
                object.points[1] = pt2;
            object.rect = pointArray.boundingRect(object.points, 0);
            object.rect.top = object.points[0].y - defaultFontSize;
            object.rect.bottom = object.points[1].y;
            object.rect.right += (4 / this.zoom);
            ctx.stroke();
        } else {
            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);

            for (let i = 0; i < points.length; i++) {
                let p1 = points[i];

                if (!p1) break;
                ctx.lineTo(p1.x, p1.y);
            }

            if (object.type === DrawingMode.measureArea || object.type === DrawingMode.area) {
                ctx.closePath();
            }

            ctx.stroke();

            if (object.type === DrawingMode.measureArea || object.type === DrawingMode.area) {
                ctx.fillStyle = object.type === DrawingMode.measureArea ? "rgba(125,125,125,0.5)" : "rgba(255,255,0,0.15)";
                ctx.fill();

                ctx.fillStyle = object.color;
            }

            if (object.type === DrawingMode.measureDistance) {
                ctx.beginPath();
                ctx.arc(object.points[0].x, object.points[0].y, 10 / this.zoom, 0, 2 * 3.14, false);
                ctx.stroke();

                if (object.points[1]) {
                    ctx.beginPath();
                    ctx.arc(object.points[1].x, object.points[1].y, 10 / this.zoom, 0, 2 * 3.14, false);
                    ctx.stroke();

                    if (object.delta > 0) {
                        let tX = object.points[0].x + ((object.points[1].x - object.points[0].x) / 2);
                        let tY = object.points[0].y + ((object.points[1].y - object.points[0].y) / 2);
                        let text = woundDraw.formatNumber(object.delta);
                        let tm: TextMetrics = ctx.measureText(text);
                        tX -= tm.width / 2;
                        tY -= 4;
                        ctx.fillStyle = "white";
                        ctx.fillText(text, tX - 1, tY - 1);

                        ctx.fillStyle = "black";
                        ctx.fillText(text, tX, tY);
                    }
                }
            } else if (object.type === DrawingMode.measureArea) {
                let pt = object.center;
                if (pt) {
                    //let textValue = this.calibrationInfo ? object.delta /= this.calibrationInfo : object.delta;
                    //let text = this.calibrationInfo ? `${textValue}cm` : textValue.toString();
                    let text = woundDraw.formatNumber(object.area);
                    let tm: TextMetrics = ctx.measureText(text);
                    let dw = tm.width / 2;
                    let x = pt.x - dw;
                    let y = pt.y;
                    ctx.fillStyle = "black";
                    ctx.fillText(text, x, y);

                    ctx.fillStyle = "white";
                    ctx.fillText(text, x + 1, y + 1);
                }
            } else if (object.type === DrawingMode.arrow) {
                this.drawArrowhead(ctx, object, 10 / this.zoom);
            }
        }

        if (object.selected) {
            ctx.lineWidth = 2 / this.zoom;
            ctx.strokeStyle = "#C0C0C0";
            ctx.setLineDash([5 / this.zoom, 15 / this.zoom]);
            ctx.beginPath();
            ctx.strokeRect(
                object.rect.left - 4,
                object.rect.top - 4,
                (object.rect.right - object.rect.left) + 4,
                (object.rect.bottom - object.rect.top) + 4
            );
            ctx.stroke();
            ctx.strokeStyle = "#0C0C0C";
            ctx.beginPath();
            ctx.strokeRect(
                object.rect.left - 5,
                object.rect.top - 5,
                (object.rect.right - object.rect.left) + 5,
                (object.rect.bottom - object.rect.top) + 5
            );
            ctx.stroke();

            ctx.setLineDash([]);
        }
    }

    static wrapText(context, object: IDrawingObject, maxWidth, lineHeight): number {
        let result: number = 0;
        if (!object || !object.text) return result;

        let words = object.text.split(" ");
        let line = "";
        let x = object.points[0].x;
        let y = object.points[0].y;

        for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + " ";
            let metrics = context.measureText(testLine);
            let testWidth = metrics.width;

            if (testWidth > maxWidth) {
                context.fillText(line, x, y);
                line = words[n] + " ";
                y += lineHeight;
                result++;
            } else {
                line = testLine;
            }
        }

        context.fillText(line, x, y);
        result++;

        return result;
    }

    /**
     * Draw an arrowhead on a line on an HTML5 canvas.
     *
     * @param context The drawing context on which to put the arrowhead.
     * @param object the IDrawingObject to use
     * @param radius The radius of the arrowhead. This controls how "thick" the arrowhead looks.
     */
    static drawArrowhead(context, object: IDrawingObject, radius) {
        let from = object.points[0];
        let to = object.points[1];

        if (!from || !to) return;
        let x_center = to.x;
        let y_center = to.y;
        let angle, x, y;

        context.fillStyle = object.color;
        context.beginPath();

        angle = Math.atan2(to.y - from.y, to.x - from.x);
        x = radius * Math.cos(angle) + x_center;
        y = radius * Math.sin(angle) + y_center;

        context.moveTo(x, y);

        angle += (1.0 / 3.0) * (2 * Math.PI);
        x = radius * Math.cos(angle) + x_center;
        y = radius * Math.sin(angle) + y_center;

        context.lineTo(x, y);

        angle += (1.0 / 3.0) * (2 * Math.PI);
        x = radius * Math.cos(angle) + x_center;
        y = radius * Math.sin(angle) + y_center;

        context.lineTo(x, y);

        context.closePath();

        context.fill();
    }

    getPreviewThumb(): Promise<string> {
        let result: string = undefined;
        if (this.canvas.toDataURL) {
            let img = new Image();
            let src = this.canvas.toDataURL('image/jpeg', 100);
            img.onload = async () => {
                let cnv = document.createElement("canvas");

                //let ctx = cnv.getContext("2d");
                let w = img.width || img.naturalWidth;
                let h = img.height || img.naturalHeight;
                let f = w / h;
                let dw = 100;
                let dh = dw / f;

                cnv.setAttribute("width", `${dw}px`);
                cnv.setAttribute("height", `${dh}px`);
                cnv.style.width = `${dw}px`;
                cnv.style.height = `${dh}px`;

                let c = cnv.getContext("2d");
                c.clearRect(0, 0, c.canvas.width, c.canvas.height);
                c.drawImage(img,
                    0, 0, w, h,
                    0, 0, dw, dh);

                result = cnv.toDataURL('image/jpeg', 100);

                // update the thumb-image too:
                let url = `Media?_format=json`
                    + `&${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`
                    + `&type=${FhirService.FhirVersion > 3 ? 'image' : 'photo'}`
                    + `&${FhirService.FhirVersion > 3 ? 'modality' : 'subtype'}=${RuntimeInfo.SystemHeader}/image-thumbnail|thumbnail`
                    + `&identifier=${RuntimeInfo.SystemHeader + "/media-source-id"}|${this.media.id}`;

                let thumbBundle = <any>await this.fhirService.get(url);
                let thumb: any = thumbBundle.entry && thumbBundle.entry.length === 1 ? <any>thumbBundle.entry[0].resource : undefined;
                if (thumb) {
                    if (!thumb.text) {
                        thumb.text = {
                            status: "generated",
                            div: '<div xmlns="http://www.w3.org/1999/xhtml">\n'
                                + `<img src="${result}" alt="thumb" />`
                                + '</div>'
                        };
                    }

                    await this.fhirService.update(thumb);
                }

                return Promise.resolve(result);
            };

            img.src = src;
        } else {
            console.warn('Kein canvas.toDataURL im Browser: ' + navigator.userAgent);
            return Promise.resolve(undefined);
        }
    }

    goBack() {
        this.router.navigateBack(); //navigateToRoute("wunden");
    }

    async saveMedia() {
        let encounterName = 'context';
        let subtypeName = 'subtype';
        if (FhirService.FhirVersion > 3) {
            encounterName = 'encounter';
            subtypeName = 'modality';
        }

        if (!this.drawingMedia) {
            this.drawingMedia = {
                id: NitTools.Uid(),
                resourceType: "Media",
                subject: {
                    reference: `Patient/${this.patient.id}`
                },
                type: "photo",
                identifier: [
                    {
                        system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/image-objects-target`,
                        value: this.media.id
                    }
                ],
                content: {
                    contentType: "text/json+NursITObjectData",
                    creation: moment(new Date()).toJSON(),
                    id: NitTools.Uid(),
                    title: this.media.content.title + ' - Drawing Objects'
                }
            }

            this.drawingMedia[encounterName] = {reference: `Encounter/${this.patient.encounterId}` };
            this.drawingMedia[subtypeName]  = {
                coding: [{
                        code: 'image-objects',
                        system: NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition) + '/mediaType'
                    }]
            };
        }

        if (UserService.Practitioner) {
            const names = <any>UserService.GetNameObject(UserService.Practitioner);
            if (names && names.text) {
                this.drawingMedia.operator = {
                    reference: 'Practitioner/' + UserService.Practitioner.id,
                    display: names.text
                }
            }
        }

        this.drawingMedia.content.data = btoa(JSON.stringify({
            drawingObjects: this.drawingObjects,
            // resultingImage: this.canvas.toDataURL('image/jpeg', 100),
            calibrationInfo: this.calibrationInfo
        }));

        // update existing images with correct  links:
        this.drawingMedia[encounterName] = {
            reference: `Encounter/${PatientItem.SelectedPatient.encounterId}`
        };

        this.drawingMedia.subject = {
            reference: `Patient/${PatientItem.SelectedPatient.id}`
        };

        RuntimeInfo.IsLoading = true;
        try {
            await this.fhirService.update(this.drawingMedia);
        } catch (e) {
            console.warn(e.response || e.message || e);
            this.dialogMessages.prompt("Fehler beim Speichern des Mediums aufgetreten" + (e.message || e), this.i18n.tr("warning"), true);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    drawingMedia: any;

    public static async LoadDrawingObjects(media: any, fhirService: FhirService): Promise<{ media: any, drawingObjects: IDrawingObject[], calibrationInfo: any }> {
        const result = {
            media: undefined,
            drawingObjects: undefined,
            calibrationInfo: undefined
        };

        if (window["__drawingMedia"]) {
            result.media = window["__drawingMedia"];
            delete window["__drawingMedia"];
        } else {
            const url = `Media?identifier=${media.id}&${FhirService.FhirVersion > 3 ? 'modality' : 'subtype'}=image-objects`;
            const bundle = <any[]>await fhirService.fetch(url);
            if (bundle && bundle[0]) result.media = bundle[0];
        }

        if (result.media) {
            try {
                const src = (<any>result.media).content.data;
                const data = JSON.parse(atob(src)); // .replace(/&quot;/g, '"'));
                if (data) {
                    result.calibrationInfo = data.calibrationInfo;
                    result.drawingObjects = data.drawingObjects || []
                }
            } catch (e) {
                console.warn(e.message);
                result.media = undefined;
            }
        }

        return result;
    }

    async loadImage() {
        try {
            RuntimeInfo.IsLoading = true;
            let url = `${fhirEnums.ResourceType.media}/${this.mediaId}`;

            const imageMedia = NitTools.Clone(window["__tempMedia"]) || <any>await this.fhirService.get(url);
            delete window["__tempMedia"];

            this.media = imageMedia;
            const data = await woundDraw.LoadDrawingObjects(this.media, this.fhirService);
            this.drawingMedia = data.media;
            this.drawingObjects = data.drawingObjects || [];
            this.calibrationInfo = data.calibrationInfo || undefined;
            if (this.drawingObjects.length > 0) {
                this.drawingMode = DrawingMode.select;
            }

            let img = new Image();
            img.onload = () => {
                let canvas = <HTMLCanvasElement>document.getElementById("imageCanvas");
                let rect = this.canvasParent.getBoundingClientRect();

                canvas.width = img.naturalWidth || img.width;
                canvas.height = img.naturalHeight || img.height;

                canvas.style.height = (rect.height - 5) + "px"; // `${canvas.height}px`;
                canvas.style.width = (rect.width - 5) + "px"; // `${canvas.width}px`;

                let fX = canvas.width / canvas.height;
                if (canvas.width < canvas.height) {
                    canvas.style.height = `${rect.width * fX}px`;
                } else {
                    canvas.style.width = `${rect.height * fX}px`;
                }

                woundDraw.zoom = this.styleSizeToNumber(canvas.style.width) / canvas.width;
                this.fX = fX;
                this.image = img;

                this.update();
            };

            img.src = "data:" + imageMedia.content.contentType + ";base64," + (<any>imageMedia.content).data;

        } catch (e) {
            console.warn(e.message)
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    touchDelta: number = 0;
    touchDeltaDown: number = 0;

    get isMobile() {
        return RuntimeInfo.IsMobile;
    }

    async svgMouseMove(e) {
        let pt = this.getCoords(e);
        e.preventDefault();

        if (!this.mouseButtonDown) return;

        if (this.drawingMode === DrawingMode.moveView) {
            if (e.touches && e.touches.length >= 2) { // Only deal with one finger
                // let rect = (<HTMLElement>this.canvas).getBoundingClientRect();

                let arr = this.calcTouchPoints(e);
                let touch1 = arr[0]; // this.pointFromTouch(e.touches[0], rect);
                let touch2 = arr[1]; // this.pointFromTouch(e.touches[1], rect);
                let delta = touch2.x - touch1.x;

                this.lastPoint = touch1;
                if (touch2) {
                    this.touchDelta = this.touchDeltaDown - delta;
                    if (this.touchDelta < -5) {
                        this.setZoom(1.01);
                    } else if (this.touchDelta > 5) this.setZoom(0.99);

                    return;
                }
            } else {
                let y = parseInt(this.canvas.getAttribute("data-top"));
                let x = parseInt(this.canvas.getAttribute("data-left"));
                let pt = this.getCoords(e);
                if (e.touches) {
                    let rect = (<HTMLElement>this.canvas).getBoundingClientRect();
                    pt = new Point(e.touches[0].clientX - rect.left,
                        e.touches[0].clientY - rect.top);
                    x = parseInt(this.canvas.getAttribute("data-left")) + (this.touch1.x - pt.x);
                    y = parseInt(this.canvas.getAttribute("data-top")) + (this.touch1.y - pt.y);

                    this.canvas.style.marginLeft = -x + "px";
                    this.canvas.style.marginTop = -y + "px";
                    this.canvas.setAttribute("data-left", x.toString());
                    this.canvas.setAttribute("data-top", y.toString());
                }
            }
        } else if (this.drawingMode === DrawingMode.measureDistance
            || this.drawingMode === DrawingMode.line
            || this.drawingMode === DrawingMode.arrow
            || this.drawingMode === DrawingMode.calibrate) {
            this.linePoints = [this.linePoints[0], pt];
            let d = pointArray.getDist(this.linePoints[0], pt);
            if (this.calibrationInfo) {
                d /= this.calibrationInfo;
            }

            this.currentObject.delta = d;
        } else if (this.drawingMode === DrawingMode.move
            && this.selectedCount > 0) {
            if (!this.linePoints) this.linePoints = [];
            let dx = this.lastPoint.x - pt.x;
            let dy = this.lastPoint.y - pt.y;
            this.drawingObjects.filter(o => o.selected && o.points[0] && o.rect)
                .forEach(o => {
                    if (o.type === DrawingMode.text) {
                        o.points[0].x -= dx;
                        o.points[0].y -= dy;
                    } else {
                        o.points.forEach(p => {
                            p.x -= dx;
                            p.y -= dy;
                        });

                        o.rect = pointArray.boundingRect(o.points, 4);
                        if (o.type === DrawingMode.measureArea) {
                            o.center = pointArray.center(o.points);
                        }
                    }
                })
        } else {
            this.linePoints.push({x: pt.x, y: pt.y});
        }

        this.lastPoint = {x: pt.x, y: pt.y};

        this.update();
    }

    async svgMouseOut() {
        this.mouseButtonDown = false;
        this.update();
    }

    calcTouchPoints(e): IPoint[] {
        //let rect = (<HTMLElement>this.canvas).getBoundingClientRect();
        let t1 = this.pointFromTouch(e.touches[0]);
        let t2 = this.pointFromTouch(e.touches[1]);
        if (t2) {
            let r: IRect = pointArray.boundingRect([t1, t2], 0);
            return [{x: r.left, y: r.top}, {x: r.right, y: r.bottom}];
        } else return [t1];
    }

    async svgMouseUp(e) {
        this.mouseButtonDown = false;

        /*
        if (e.touches) {
          // let rect = (<HTMLElement>this.canvas).getBoundingClientRect();
          let arr = this.calcTouchPoints(e);
          this.touch1 = arr[0]; // this.pointFromTouch(e.touches[0], rect);
          this.touch2 = arr[1]; // this.pointFromTouch(e.touches[1], rect);
        } */

        if (this.drawingMode === DrawingMode.select
            || this.drawingMode === DrawingMode.move) return;

        let p0 = this.lastPoint;
        let p1 = this.lastPoint;

        if (e.touches) {
            p0 = this.pointFromTouch(e.touches[0]);
            p1 = this.pointFromTouch(e.touches[0]);
        }

        if (this.currentObject) {
            p0 = this.currentObject.points[0];
            p1 = this.currentObject.points[this.currentObject.points.length - 1];
            this.currentObject.rect = pointArray.boundingRect(this.currentObject.points, 0);
        }

        if (this.drawingMode === DrawingMode.area || this.drawingMode === DrawingMode.measureArea) {
            if (this.linePoints.length <= 3) {
                this.drawingObjects.forEach(d => d.selected = false);
                this.currentObject.selected = true;
                this.deleteSelectedObject();
            } else {
                this.linePoints.push(new Point(p0.x, p0.y));
                this.linePoints = await pointArray.simplifyClosedArea(this.linePoints, 2, true);
                this.currentObject.area = await pointArray.calcPolygonArea(this.linePoints);
                if (this.calibrationInfo) {
                    this.currentObject.area = this.currentObject.area / (this.calibrationInfo * this.calibrationInfo);
                }

                this.currentObject.center = pointArray.center(this.linePoints);
            }
        } else if (this.drawingMode === DrawingMode.freeHand) {
            this.linePoints = await pointArray.simplify(this.linePoints, 1, true);
        } else if (this.drawingMode === DrawingMode.line || this.drawingMode === DrawingMode.arrow) {
            this.linePoints = [p0, p1];
            this.currentObject.delta = pointArray.getDist(p0, p1);
            if (this.currentObject.delta === 0) {
                this.drawingObjects.forEach(o => o.selected = false);
                this.currentObject.selected = true;
                this.deleteSelectedObject();
            }
        } else if (this.drawingMode === DrawingMode.calibrate) {
            this.calibrationInfo = pointArray.getDist(p0, p1);
            this.currentObject.points = [p0, p1];
            this.drawingMode = DrawingMode.freeHand;
            for (const o of this.drawingObjects) {
                if (o.type === DrawingMode.measureArea) {
                    o.area = await pointArray.calcPolygonArea(o.points);
                    if (this.calibrationInfo) {
                        o.area = o.area / (this.calibrationInfo * this.calibrationInfo);
                    }
                } else if (o.type === DrawingMode.measureDistance) {
                    o.delta = pointArray.getDist(o.points[0], o.points[1]) / this.calibrationInfo;
                }
            }
        } else if (this.drawingMode === DrawingMode.measureDistance) {
            if (this.currentObject.delta === 0) {
                this.drawingObjects.forEach(o => o.selected = false);
                this.currentObject.selected = true;
                this.deleteSelectedObject();
            } else {
                if (ConfigService.Debug) {
                    console.debug(this.currentObject.delta);
                }
            }
        }

        this.update();
    }

    isDoubleFinger: boolean = false;

    async svgMouseDown(e) {
        let pt = this.getCoords(e);

        if (e.touches) {
            // let rect = (<HTMLElement>this.canvas).getBoundingClientRect();
            let arr = this.calcTouchPoints(e);
            this.touch1 = arr[0]; // this.pointFromTouch(e.touches[0], rect);
            this.touch2 = arr[1]; // this.pointFromTouch(e.touches[1], rect);
            this.touchDelta = 0;
            this.isDoubleFinger = typeof this.touch2 !== "undefined";
            this.touchDeltaDown = this.touch2 ? this.touch2.x - this.touch1.x : 0;
        }

        if (this.drawingMode !== DrawingMode.select
            && this.drawingMode !== DrawingMode.move) {
            this.mouseButtonDown = true;
            this.currentObject = new DrawingObject(this.drawingMode);
            this.currentObject.color = this.color;
            this.currentObject.points.push(new Point(pt.x, pt.y));
            this.currentObject.penSize = this.penSize;
            if (this.drawingMode !== DrawingMode.calibrate) this.drawingObjects.push(this.currentObject);

            if (this.drawingMode === DrawingMode.text) {
                this.mouseButtonDown = false;
                await this.dialogService.open({
                    viewModel: PromptInput,
                    model: {
                        message: this.i18n.tr("draw_enter_text"),
                        title: this.i18n.tr('information'),
                        yesText: this.i18n.tr("ok"),
                        noText: this.i18n.tr('abort')
                    },
                    lock: true
                })
                    .whenClosed(dialogResult => {
                        if (dialogResult.wasCancelled || !dialogResult.output) {
                            this.drawingObjects.forEach(o => o.selected = false);
                            this.currentObject.selected = true;
                            this.deleteSelectedObject();
                        } else {
                            this.currentObject.text = dialogResult.output;
                            this.update();
                        }
                    })
            }
        } else if (this.drawingMode === DrawingMode.select) {
            // select mode:
            this.findElementAt(pt);
        } else if (this.drawingMode === DrawingMode.move) {
            this.mouseButtonDown = true;
        }

        this.lastPoint = new Point(pt.x, pt.y);
        this.update();
    }

    selectedCount: number = 0;

    findElementAt(pt: IPoint) {
        this.drawingObjects.forEach(o => o.selected = false);
        let arr = [];
        try {
            arr = this.drawingObjects.filter(o => o.rect && pt.x > o.rect.left - 5 && pt.x < o.rect.right + 5);
            arr = arr.filter(o => pt.y > o.rect.top - 5 && pt.y < o.rect.bottom + 5);
            arr.forEach(o => o.selected = true);
        } catch (e) {
            if (ConfigService.Debug)
                console.warn("Find Element @ ", pt, e.message || e);
        }

        this.selectedCount = arr.length;
    }

    deleteSelectedObject() {
        for (let i = this.drawingObjects.length - 1; i >= 0; i--) {
            if (this.drawingObjects[i].selected) {
                this.drawingObjects.splice(i, 1);
            }
        }

        this.selectedCount = 0;

        this.update();
    }

    canvasParent: HTMLDivElement;

    detached() {
        document.body.classList.remove("no-toolbar-window");
    }

    textMode() {
        this.drawingMode = DrawingMode.text;
    }

    arrowMode() {
        this.drawingMode = DrawingMode.arrow;
    }

    patient: PatientItem = undefined;
    //colorpicker_edit: HTMLInputElement;
    spectrum: any;

    async attached() {
        document.body.classList.add("no-toolbar-window");
        this.taskQueue.queueTask(async () => {
            await this.loadImage();
            if (this.encounterId) this.patient = await PatientItem.Load(this.encounterId);
            this.drawingMode = DrawingMode.select;
            this.penSize = 4;

            this.spectrum = $('#colorpicker').spectrum({
                color: this.color,
                change: (color) => {
                    this.colorChanged(color.toHexString());
                }
            });
        });
    }

    // is called from external JS function "initSpectrum" in index.ejs
    colorChanged(color: string) {
        this.color = color;

        if (this.selectedCount === 1) {
            let obj = this.drawingObjects.find(o => o.selected);
            if (obj) {
                obj.color = this.color;
            }

            this.update();
        }
    }
}


export enum DrawingMode {
    select = "select",
    move = "move",
    text = "text",
    calibrate = "calibrate",
    area = "area",
    line = "line",
    freeHand = "freehand",
    measureDistance = "measure-distance",
    arrow = "arrow",
    measureArea = "measure-area",
    moveView = "move-view"
}

export interface IPoint {
    x: number;
    y: number;
}

export class Point implements IPoint {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}

export interface IRect {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

export interface IDrawingObject {
    type: DrawingMode;
    points: IPoint[];
    area: number;
    delta: number;
    lastPoint: IPoint;
    center: IPoint;
    selected: boolean;
    text: string;
    rect: IRect;
    color: string;
    penSize: number;
}

export class DrawingObject implements IDrawingObject {
    type: DrawingMode;
    points: IPoint[];
    area: number;
    delta: number;
    center: IPoint;
    selected: boolean;
    text: string;
    rect: IRect;
    color: string;
    penSize: number;

    constructor(type: DrawingMode) {
        this.type = type;
        this.points = [];
        this.area = 0;
        this.delta = 0;
        this.center = undefined;
        this.selected = false;
        this.text = undefined;
    }

    get lastPoint(): IPoint {
        return this.points[this.points.length - 1];
    }
}
